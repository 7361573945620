/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-match-height@0.7.2/dist/jquery.matchHeight.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/wowjs@1.1.3/dist/wow.min.js
 * - /npm/bootstrap@4.6.2/dist/js/bootstrap.min.js
 * - /npm/sweetalert2@8.19.1/dist/sweetalert2.all.min.js
 * - /npm/headroom.js@0.9.4/dist/headroom.min.js
 * - /npm/headroom.js@0.9.4/dist/jQuery.headroom.min.js
 * - /npm/jquery-waypoints@2.0.5/waypoints.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
